// -----------------------------------------------------------------------------
// Keyv style
// -----------------------------------------------------------------------------
#keyv {
    margin: 0 auto;
    padding: 100px 0 0;
    max-width: 1080px;
    .kInner {
        padding: 29px 0 20px 61px;
        background: url(/assets/img/home/bg_keyv_pc.png) no-repeat right 0 bottom 0;
        background-size: cover;
        .title {
            font-family: 'Petrona', serif;
            font-size: 54px;
            background: -webkit-linear-gradient(#6FC3E9, #94DBFC, #56B2DC);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
    .kSection {
        width: 65%;
        .ttl {
            margin-bottom: 10px;
            line-height: 2;
            font-size: 23px;
        }
        .txt {
            margin-bottom: 15px;
            line-height: 2.1;
            font-size: 15px;
            span {
                background: linear-gradient(0deg, rgba(148,219,252,1) 50%, rgba(148,219,252,1) 80%) repeat-x left 0% bottom -1px;
                background-size: 100% 35%;
                font-weight: bold;
            }
        }
    }
    @include media-down(1040) {
        padding-top: 62px;
        .kInner {
            padding: 25px 30px 20px;
            .title {
                font-size: 48px;
            }
        }

        .kSection {
            .ttl {
                margin-bottom: 15px;
                font-size: 21px;
            }
            .txt {
                line-height: 2.2;
                font-size: 14px;
            }
            .btn {
                padding: 20px 15px 20px;
                max-width: 320px;
                font-size: 16px;
            }
        }
    }
    @include media-down(sp) {
        .blockSp {
            position: relative;
            .image {
                img {
                    vertical-align: middle;
                    width: 100%;
                    object-fit: cover;
                }
            }
            .ttlSp {
                position: absolute;
                bottom: 30px;
                left: 50%;
                width: 100%;
                color: #FFF;
                font-size: 34px;
                font-family: 'Petrona', serif;
                font-weight: bold;
                text-align: center;
                transform: translateX(-50%);
            }
        }
        .kInner {
            padding: 25px 25px 20px;
            background: #FFF;
        }
        .kSection {
            width: 100%;
            .ttl {
                line-height: 1.95;
                font-size: 23px;
                text-align: center;
            }
            .txt {
                margin-bottom: 0;
                font-size: 14px;
                span {
                    background-position: left 0% bottom 1px;
                }
            }
        }
    }
    @include media-down(475) {
        background-size: contain;
    }
}
