// -----------------------------------------------------------------------------
// All other style
// -----------------------------------------------------------------------------

.bgAqua {
    padding: 80px 0;
    background-color: #F4F8F9 !important;
    @include media-down(sp) {
        padding: 40px 0 45px 0;
        background-color: #FFF !important;
    }
}

.bgWhite {
    padding: 80px 0;
    background-color: #FFF !important;
    @include media-down(sp) {
        padding: 40px 0 45px 0;
        background-color: #F4F8F9 !important;
    }
}

.txtCommon {
    font-size: 15px;
    line-height: 2.5;
    @include media-down(sp) {
        line-height: 2.3;
    }
}

.txtCommon01 {
    font-size: 13px;
    line-height: 1.8;
}

.txtCommon02 {
    font-size: 13px;
    line-height: 2.5;
    @include media-down(sp) {
        font-size: 10px;
        line-height: 2.3;
    }
}

.listSupport {
    display: flex;
    li {
        padding: 0 10px;
        border-right: 2px dotted #94DBFC;
        width: calc(100% / 3);
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        justify-content: space-between;
        &:last-child {
            border-right: none;
        }
        .ttl {
            margin-bottom: 30px;
            font-size: 25px;
            line-height: 1.8;
            font-weight: bold;
            min-height: 90px;
        }
        .ttl2 {
            margin-bottom: 15px;
            font-size: 18px;
            line-height: 1.8;
            font-weight: bold;
        }
        .price {
            margin-bottom: 20px;
            line-height: 2;
            font-size: 15px;
            span {
                font-size: 18px;
                font-weight: bold;
                color: $color01;
            }
        }
        .boxImg {
            margin: 0 auto;
            max-width: 165px;
            width: 100%;
            img {
                display: block;
                margin: 0 auto;
                width: 100%;
            }
        }
        .boxImg2 {
            margin: 0 auto;
            max-width: 230px;
            width: 100%;
            img {
                display: block;
                margin: 0 auto;
            }
        }
    }
    .headline2 {
        justify-content: center;
        margin-bottom: 15px;
    }
    .ttl {
        margin-bottom: 38px;
        font-size: 20px;
    }

    @include media-down(sp) {
        flex-direction: column;
        li {
            padding: 55px 0;
            border-right: none;
            border-bottom: 2px dotted #94DBFC;
            width: 100%;
            .boxImg {
                max-width: 140px;
            }
            &:first-child {
                padding-top: 0;
            }
            &:last-child {
                .sGroup {
                    margin-bottom: 37px;
                }
                .boxImg {
                    max-width: 160px;
                }
            }
            .ttl {
                margin-bottom: 22px;
                font-size: 18px;
                line-height: 1.6;
                min-height: auto;
            }
            .ttl2 {
                font-size: 18px;
            }
            .price {
                margin-bottom: 15px;
            }
        }
        &.style01 {
            li:last-child {
                border-bottom: 0;
                padding-bottom: 0;
            }
        }
    }
}

@media all and (-ms-high-contrast:none) {
    .listSupport {
        li {
            display: block;
        }
    }
}

.giftlogisticsSection {
    padding: 80px 0;
    @include media-down(sp) {
        padding: 55px 0;
    }
}

.boxContent {
	display: flex;
	justify-content: space-between;
	.boxImg {
		width: 49%;
		img {
            vertical-align: middle;
		}
	}

	.boxInfo {
		width: calc(50% - 45px);
        .ttl {
            margin-bottom: 30px;
            font-size: 27px;
            font-weight: bold;
            color: #222;
            line-height: 1.5;
        }
		.txt {
            font-size: 15px;
            line-height: 2.15;
			margin-bottom: 25px;
		}
	}
    &02 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .blockTxt {
            width: calc(50% - 80px);
            .headline3 {
                margin:  0 0 55px;
                text-align: left;
            }
        }
       .imgDiagram {
           width: 50%;
           img {
               vertical-align: middle;
           }
        }
    }
	@include media-down(sp) {
        flex-wrap: wrap;
		.boxImg {
            margin: 0 auto 20px;
			width: 100%;
            text-align: center;
		}
		.boxInfo {
            margin-bottom: 15px;
			width: 100%;
            .ttl {
                margin-bottom: 20px;
                font-size: 18px;
            }
			.txt {
                line-height: 1.8;
				margin-bottom: 0;
			}
		}
        &02 {
            flex-wrap: wrap;
            .blockTxt {
                width: 100%;
                margin-bottom: 20px;
                .headline3 {
                    margin: 0 auto 30px;
                    text-align: center;
                }
            }
           .imgDiagram {
               width: 100%;
               max-width: 260px;
               margin: 0 auto;
            }
        }
	}
}

.blockEndow {
    .headline2 {
        position: relative;
        margin-bottom: 12px;
        padding-bottom: 15px;
        width: fit-content;
        &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            background: url(/assets/img/common/bg_circle.png) repeat-x left -2px top;
            width: 100%;
            height: 5px;
        }
    }
}

.sectionServices {
    .txtCommon {
        padding: 0 50px;
    }
    .servicesImage {
        display: block;
        margin: 0 auto;
        img {
            display: block;
            margin: 0 auto;
        }
    }
    @include media-down(sp) {
        .txtCommon {
            padding: 0;
        }
        .servicesImage {
            max-width: 290px;
        }
    }
}

.sectionSchedule {
    .scheduleImage {
        display: block;
        margin: 0 auto 50px auto;
        img {
            display: block;
            width: 100%;
            margin: 0 auto;
        }
    }
	@include media-down(sp) {
        .scheduleImage {
            max-width: 290px;
        }
    }
}

.sectionOption {
    .headline1 {
        margin-bottom: 25px;
    }

    @include media-down(sp) {
        .headline1 {
            line-height: 1.8;
            padding-bottom: 40px;
            margin-bottom: 45px;
            span {
                margin-top: 15px;
            }
        }
    }
}


.blockText {
    margin-bottom: 80px;
    padding-left: 10px;
    @include media-down(sp) {
        margin-bottom: 50px;
        padding-left: 0;
    }
}

.contactSection {
	padding: 80px 0;
	text-align: center;
	.headline1 {
			margin-bottom: 40px;
            padding-bottom: 0;
            border-bottom: 0;
			span {
				margin-top: 22px;
				font-size: 20px;
			}
		}
	.boxTxt {
		margin-bottom: 50px;
        .txt {
            font-size: 15px;
            line-height: 2;
        }
	}
	.btn {
		padding: 28px 15px;
		border: none;
		max-width: 465px;
		cursor: pointer;
	}
	@include media-down(sp) {
		padding: 40px 0 45px 0;
		.headline1 {
			margin-bottom: 22px;
		}
		.btn {
			padding: 16px 15px;
			max-width: 290px;
		}
	}
}

.ieMode {
	.kInner .title {
		color: #6FC3E9;
	}
}
