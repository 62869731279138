// -----------------------------------------------------------------------------
// This file contains styles that are specific to the Contact page
// -----------------------------------------------------------------------------
.container {
	margin: 0 auto 35px;
	padding: 30px 50px 22px;
	max-width: 890px;
	background-color: #F4F8F9;
	input[type=text], select, textarea {
		width: 100%;
		padding: 17px 16px;
		border: none;
		box-shadow: inset 0px 0px 15px -2px rgba(0, 0, 0, 0.25);
		border-radius: 4px;
		box-sizing: border-box;
		margin-bottom: 20px;
		font-size: 14px;
		resize: none;
		outline: none;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
	}
	select {
		-moz-appearance: none;
		appearance: none;
		background-image:  url(/assets/img/common/icon_arrrow.png);
		background-repeat: no-repeat;
		background-position: right 35px top 50%;
		background-size: 16px;
	}
	select::-ms-expand {
		display: none;
	}
	textarea {
		padding: 52px 40px;
		height: 400px;
		overflow: auto;
	}
	@include media-down(sp)  {
		margin-bottom: 50px;
		padding: 15px 13px;
		select {
			background-position: right 20px top 50%;
			background-size: 13px;
		}
		textarea {
			padding: 35px 16px;
			height: 265px;
		}
	}
}