// -----------------------------------------------------------------------------
// Headline style
// -----------------------------------------------------------------------------
.headline1 {
    margin-bottom: 70px;
    padding-bottom: 35px;
    border-bottom: 1px solid #C4C4C4;
    color: #56B2DC;
    font-size: 34px;
    text-align: center;
    font-family: 'Petrona', serif;
    span {
        display: block;
        margin-top: 20px;
        font-family: "Noto Sans JP";
        color: #222;
        font-size: 27px;
        line-height: 1.5;
    }
    &.style01 {
        margin-bottom: 40px;
    }
    @include media-down(sp) {
        margin-bottom: 47px;
        padding-bottom: 47px;
        font-size: 34px;
        span {
            margin-top: 20px;
            font-size: 27px;
        }
        &.style01 {
            margin-bottom: 30px;
            padding-bottom: 17px;
            span {
                line-height: 2.2;
                margin-top: 10px;
            }
        }
    }
}

.headline2 {
    display: inline-flex;
    align-items: center;
    font-size: 27px;
    font-weight: bold;
    margin-bottom: 20px;
    em {
        font-size: 14px;
        margin-right: 10px;
        margin-top: 5px;
    }
    span {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        color: #fff;
        min-width: 33px;
        height: 33px;
        font-family: 'Petrona', serif;
        font-size: 20px;
        font-weight: normal;
        z-index: 1;
        &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            border-radius: 50%;
            background: #56B2DC;
            width: 100%;
            height: 100%;
            transform: translate(-50%, -50%);
            z-index: -1;
        }
    }
    @include media-down(sp) {
        margin-bottom: 15px;
        font-size: 24px;
    }
}

.headline3 {
    display: block;
    width: fit-content;
    margin: 0 auto 35px;
    text-align: center;
    span {
        background: linear-gradient(0deg, #94dbfc 30%, #94dbfc 60%) repeat-x left 0% bottom 0;
        background-size: 100% 15%;
        font-size: 27px;
        line-height: 2;
    }
    @include media-down(sp) {
        margin-bottom: 30px;
        font-size: 18px;
        span {
            font-size: 18px;
        }
    }
}
