.listCommon01 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &::after {
        width: calc((100% - 70px) / 3);
        content: '';
    }
    li {
        width: calc((100% - 70px) / 3);
        margin-bottom: 35px;
        background-color: $color-white;
        img {
            display: block;
            width: 100%;
            margin: 0 auto;
        }
    }
    @include media-down(sp) {
        flex-direction: column;
        padding: 0;
        &::after {
            display: none;
        }
        li {
            width: 100%;
            max-width: 285px;
            margin: 0 auto 15px auto;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.listCommon02 {
    display: flex;
    margin: 0 -10px;
    li {
        width: calc(25% - 20px);
        margin: 0 10px 0 10px;
        .ttl {
            margin-bottom: 10px;
            font-size: 15px;
            font-weight: bold;
            line-height: 2;
            text-align: center;
        }
        .image {
            margin-bottom: 15px;
            img {
                display: block;
                margin: 0 auto;
            }
        }
        .txt {
            font-size: 15px;
            line-height: 1.7;
        }
    }
    @include media-down(sp) {
        margin: 0;
        flex-direction: column;
        li {
            width: 100%;
            margin: 0 0 40px 0;
            &:last-child {
                margin-bottom: 0;
            }
            .image {
                img {
                    max-width: 230px;
                }
            }
        }
    }
}

.groupTag {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -2px 20px -2px;
    .tag {
        margin: 0 2px 9px 2px;
    }
    @include media-down(sp) {
        margin: 0 -3px 20px -3px;
        .tag {
            margin: 0 3px 9px 3px;
        }
    }
}

.tag {
    display: inline-flex;
    padding: 2px 2px;
    border: 1px solid $color01;
    border-radius: 2px;
    font-size: 15px;
    background-color: $color-white;
    @include media-down(sp) {
        padding: 1px 1px;
    }
}
