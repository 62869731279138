// -----------------------------------------------------------------------------
// Button style
// -----------------------------------------------------------------------------
.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
	padding: 24px 15px;
	border-radius: 8px;
	box-shadow: 2px 2px 5px rgba(0,0,0, 0.25);
	background: #56B2DC;
	color: #fff;
	width: 100%;
    max-width: 365px;
    border: 0;
    outline: none;
    appearance: none;
	font-size: 18px;
	font-weight: bold;
	text-align: center;
	text-decoration: none;
	transition: 0.3s;
    &:hover {
        background: $color02;
        color: #fff;
        text-decoration: none;
        transition: 0.3s;
        cursor: pointer;
    }
	@include media-down(sp) {
		margin: 0 auto;
		box-shadow: 0px 5px 9px 1px rgb(0 0 0 / 17%);
		max-width: 290px;
		padding: 19px 15px 18px;
		font-size: 16px;
	}
}
#keyv .btn {
    max-width: 420px;
}
.btnList {
	display: flex;
	justify-content: space-between;
	flex-flow: wrap;
	max-width: 884px;
	margin: 0 auto 60px;
	li {
		width: calc((100% - 40px) / 2);
	}
	@include media-down(sp) {
		margin: 0 auto 50px;
		li {
			width: 100%;
			margin-bottom: 30px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}
.contactBtn {
	max-width: 430px;
	margin: 0 auto;
	&01 {
		padding: 0 10px;
	}
}
.btnCommon {
    display: inline-block;
    margin-bottom: 20px;
    padding: 9px 15px;
    font-weight: bold;
    background-color: $color01;
    color: $color-white;
    font-size: 13px;
}
.blockTwoBtn {
    display: flex;
    justify-content: space-between;
    max-width: 810px;
    width: 100%;
    margin: 0 auto;
    padding-top: 60px;
    &.style01 {
        padding-top: 40px;
    }
    .btn {
        width: calc(50% - 35px);
        max-width: 365px;
    }
    @include media-down(sp) {
        flex-direction: column;
        padding-top: 50px;
        &.style01 {
            padding-top: 50px;
        }
        .btn {
            width: 100%;
            max-width: 290px;
            margin-bottom: 30px;
            padding: 21px 10px 17px;
            font-size: 15px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
