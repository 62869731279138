@charset "UTF-8";
// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/// Regular font family
/// @type List
$text-font-stack: Noto Sans JP,
ヒラギノ角ゴシック Pro,
Hiragino Kaku Gothic Pro,
メイリオ,
Meiryo,
Osaka,
ＭＳ Ｐゴシック,
MS PGothic,
Verdana,
Arial,
sans-serif !default;

/// Text color
/// @type Color
$text-color: #222 !default;

/// Link color
/// @type Color
$link-color: #36C !default;

/// All other color
/// @type Color
$color-primary: #178CD6 !default;
$color-secondary: lighten($color-primary, 10%) !default;

$color-success: green !default;
$color-warning: yellow !default;
$color-error: #e5004f !default;
$color-black: #000 !default;
$color-white: #FFF !default;
$color-blue: #94DBFC !default;

$color01: #2291AF !default;
$color02: #44829E !default;


// Hover
$color-primary-hover: lighten($color-primary, 30%) !default;


/// Container's maximum width
/// @type Length
$max-width: 1240px !default;

/// Spacing on the left and the right
/// @type Length
$grid-gutter-width: 20px;
$grid-gutter-width-sp: 15px;

/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$point-up: (xs: 375.01px,
    sm: 576.01px,
    sp: 768.01px,
    md: 768.01px,
    lg: 992.01px,
    xl: 1200.01px) !default;
$point-down: (xss: 320px,
    xs: 375px,
    sm: 576px,
    sp: 768px,
    md: 992px,
    lg: 1200px) !default;


/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
/// $base-url: 'http://cdn.example.com/assets/';
$base-url: '/assets/' !default;

$transition-duration: .3s !default;
