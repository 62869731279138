@charset "UTF-8";
/* stylelint-disable */
/* ******************************************************************

    --common.css--

    0. BoxModel change

    1. Common setting
        1-1. Reset styles
        1-2. Font styles
        1-3. General styles
        1-4. Text styles
        1-5. List styles
        1-6. CSS3 common styles

****************************************************************** */
/*==================================================================
    0. BoxModel change
===================================================================*/
/*==================================================================
    1. Common setting
===================================================================*/
/* ------------------------------------------------------------------
    1-1. Reset styles
-------------------------------------------------------------------*/
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
object,
iframe,
pre,
code,
p,
blockquote,
form,
fieldset,
legend,
table,
th,
td,
caption,
tbody,
tfoot,
thead,
article,
aside,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
audio,
video,
canvas {
  margin: 0;
  padding: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
menu {
  display: block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden] {
  display: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset,
img {
  border: none;
}

address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-style: normal;
  font-weight: normal;
}

ul,
ol,
menu {
  list-style: none;
}

caption,
th {
  text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

q:before,
q:after {
  content: "";
}

abbr,
acronym {
  border: none;
  font-variant: normal;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

legend {
  color: #000;
}

a img,
map a {
  border: none;
}

a:hover,
a:active,
a:focus {
  outline: 0;
}

embed {
  width: 100%;
}

audio,
canvas,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

img,
object,
embed {
  max-width: 100%;
  height: auto;
}

object,
embed {
  height: 100%;
}

img {
  -ms-interpolation-mode: bicubic;
}

/* Font styles
-------------------------------------------------------------------*/
select,
input,
button,
textarea,
button {
  font-size: inherit;
}

table {
  font-size: inherit;
  font: 100%;
}

pre,
code,
kbd,
samp,
tt {
  font-family: monospace;
  line-height: 1;
}

/* General styles
-------------------------------------------------------------------*/
table {
  empty-cells: show;
}

input {
  line-height: 1;
}

form img,
input,
select {
  vertical-align: middle;
}

textarea {
  resize: none;
}

select {
  padding: 1px;
}

label {
  margin-right: 5px;
}

legend {
  display: none;
}

input[type=text],
input[type=password],
textarea {
  padding: 3px 4px 0 3px;
  border: 1px solid #999;
  border-right: 1px solid #CCC;
  border-bottom: 1px solid #CCC;
  font-size: 1.3rem;
}

/* ------------------------------------------------------------------
    1-2. VisualFormattingModel styles
-------------------------------------------------------------------*/
.noDisplay {
  display: none;
}

.block {
  display: block !important;
}

.inline {
  display: inline !important;
}

.inlineBlock {
  display: inline-block !important;
}

.static {
  position: static !important;
}

.relative {
  position: relative !important;
}

.absolute {
  position: absolute !important;
}

.fixed {
  position: fixed !important;
}

.leftBox {
  float: left;
}

.rightBox {
  float: right;
}

.nofloat {
  float: none !important;
}

.clearfix {
  zoom: 1;
}

.clearfix:after {
  display: block;
  clear: both;
  content: "";
}

.clear {
  clear: both !important;
}

a.hover:hover,
input.hover:hover {
  filter: alpha(opacity=70);
  -moz-opacity: 0.7;
  -khtml-opacity: 0.7;
  opacity: 0.7;
}

/* ------------------------------------------------------------------
    1-3. BoxModel styles
-------------------------------------------------------------------*/
.auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.mt0,
.mv0,
.ma0 {
  margin-top: 0 !important;
}

.mr0,
.mh0,
.ma0 {
  margin-right: 0 !important;
}

.mb0,
.mv0,
.ma0 {
  margin-bottom: 0 !important;
}

.ml0,
.mh0,
.ma0 {
  margin-left: 0 !important;
}

.pt0,
.pv0,
.pa0 {
  padding-top: 0 !important;
}

.pr0,
.ph0,
.pa0 {
  padding-right: 0 !important;
}

.pb0,
.pv0,
.pa0 {
  padding-bottom: 0 !important;
}

.pl0,
.ph0,
.pa0 {
  padding-left: 0 !important;
}

.mt5,
.mv5,
.ma5 {
  margin-top: 5px !important;
}

.mr5,
.mh5,
.ma5 {
  margin-right: 5px !important;
}

.mb5,
.mv5,
.ma5 {
  margin-bottom: 5px !important;
}

.ml5,
.mh5,
.ma5 {
  margin-left: 5px !important;
}

.pt5,
.pv5,
.pa5 {
  padding-top: 5px !important;
}

.pr5,
.ph5,
.pa5 {
  padding-right: 5px !important;
}

.pb5,
.pv5,
.pa5 {
  padding-bottom: 5px !important;
}

.pl5,
.ph5,
.pa5 {
  padding-left: 5px !important;
}

.mt10,
.mv10,
.ma10 {
  margin-top: 10px !important;
}

.mr10,
.mh10,
.ma10 {
  margin-right: 10px !important;
}

.mb10,
.mv10,
.ma10 {
  margin-bottom: 10px !important;
}

.ml10,
.mh10,
.ma10 {
  margin-left: 10px !important;
}

.pt10,
.pv10,
.pa10 {
  padding-top: 10px !important;
}

.pr10,
.ph10,
.pa10 {
  padding-right: 10px !important;
}

.pb10,
.pv10,
.pa10 {
  padding-bottom: 10px !important;
}

.pl10,
.ph10,
.pa10 {
  padding-left: 10px !important;
}

.mt20,
.mv20,
.ma20 {
  margin-top: 20px !important;
}

.mr20,
.mh20,
.ma20 {
  margin-right: 20px !important;
}

.mb20,
.mv20,
.ma20 {
  margin-bottom: 20px !important;
}

.ml20,
.mh20,
.ma20 {
  margin-left: 20px !important;
}

.pt20,
.pv20,
.pa20 {
  padding-top: 20px !important;
}

.pr20,
.ph20,
.pa20 {
  padding-right: 20px !important;
}

.pb20,
.pv20,
.pa20 {
  padding-bottom: 20px !important;
}

.pl20,
.ph20,
.pa20 {
  padding-left: 20px !important;
}

.mt30,
.mv30,
.ma30 {
  margin-top: 30px !important;
}

.mr30,
.mh30,
.ma30 {
  margin-right: 30px !important;
}

.mb30,
.mv30,
.ma30 {
  margin-bottom: 30px !important;
}

.ml30,
.mh30,
.ma30 {
  margin-left: 30px !important;
}

.pt30,
.pv30,
.pa30 {
  padding-top: 30px !important;
}

.pr30,
.ph30,
.pa30 {
  padding-right: 30px !important;
}

.pb30,
.pv30,
.pa30 {
  padding-bottom: 30px !important;
}

.pl30,
.ph30,
.pa30 {
  padding-left: 30px !important;
}

.mt40,
.mv40,
.ma40 {
  margin-top: 40px !important;
}

.mr40,
.mh40,
.ma40 {
  margin-right: 40px !important;
}

.mb40,
.mv40,
.ma40 {
  margin-bottom: 40px !important;
}

.ml40,
.mh40,
.ma40 {
  margin-left: 40px !important;
}

.pt40,
.pv40,
.pa40 {
  padding-top: 40px !important;
}

.pr40,
.ph40,
.pa40 {
  padding-right: 40px !important;
}

.pb40,
.pv40,
.pa40 {
  padding-bottom: 40px !important;
}

.pl40,
.ph40,
.pa40 {
  padding-left: 40px !important;
}

.bt0,
.ba0 {
  border-top: none !important;
}

.br0,
.ba0 {
  border-right: none !important;
}

.bb0,
.ba0 {
  border-bottom: none !important;
}

.bl0,
.ba0 {
  border-left: none !important;
}

.mb50 {
  margin-bottom: 50px !important;
}

.mb20sp50 {
  margin-bottom: 20px !important;
}
@media only screen and (max-width: 768px) {
  .mb20sp50 {
    margin-bottom: 50px !important;
  }
}

.mb40sp30 {
  margin-bottom: 40px !important;
}
@media only screen and (max-width: 768px) {
  .mb40sp30 {
    margin-bottom: 30px !important;
  }
}

.mb60sp20 {
  margin-bottom: 60px !important;
}
@media only screen and (max-width: 768px) {
  .mb60sp20 {
    margin-bottom: 20px !important;
  }
}

.mb50sp20 {
  margin-bottom: 50px !important;
}
@media only screen and (max-width: 768px) {
  .mb50sp20 {
    margin-bottom: 20px !important;
  }
}

.mb70sp40 {
  margin-bottom: 70px !important;
}
@media only screen and (max-width: 768px) {
  .mb70sp40 {
    margin-bottom: 40px !important;
  }
}

.pl10sp0 {
  padding-left: 10px !important;
}
@media only screen and (max-width: 768px) {
  .pl10sp0 {
    padding-left: 0 !important;
  }
}

/* ------------------------------------------------------------------
    1-4. Text styles
-------------------------------------------------------------------*/
strong,
.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.note {
  margin-left: 1em;
  text-indent: -1em;
}

.f10 {
  font-size: 77%;
}

.f11 {
  font-size: 85%;
}

.f12 {
  font-size: 93%;
}

.f14 {
  font-size: 108%;
}

.f15 {
  font-size: 116%;
}

.f16 {
  font-size: 123.1%;
}

.f17 {
  font-size: 131%;
}

.f18 {
  font-size: 138.5%;
}

.f19 {
  font-size: 146.5%;
}

.f20 {
  font-size: 153.9%;
}

.f21 {
  font-size: 161.6%;
}

.f22 {
  font-size: 167%;
}

.f23 {
  font-size: 174%;
}

.f24 {
  font-size: 182%;
}

.f25 {
  font-size: 189%;
}

.f26 {
  font-size: 197%;
}

.red {
  color: #C00;
}

.white {
  color: #FFF;
}

.grayC {
  color: #CCC;
}

.gray9 {
  color: #999;
}

.gray6 {
  color: #666;
}

.gray3 {
  color: #333;
}

.black {
  color: #000;
}

.middle {
  vertical-align: middle !important;
}

.center {
  text-align: center !important;
}

.centerPc {
  text-align: center !important;
}
@media only screen and (max-width: 768px) {
  .centerPc {
    text-align: left !important;
  }
}

.left {
  text-align: left !important;
}

.right {
  text-align: right !important;
}

.underline {
  text-decoration: underline !important;
}

/* ------------------------------------------------------------------
    1-5. List styles
-------------------------------------------------------------------*/
.disc {
  padding-left: 2em;
  list-style: disc;
}

.circle {
  padding-left: 2em;
  list-style: circle;
}

.decimal {
  padding-left: 2.4em;
  list-style: decimal;
}

/* ------------------------------------------------------------------
    1-6. CSS3 common styles
-------------------------------------------------------------------*/
.boxShadow {
  -webkit-box-shadow: 0 0 5px #DDD;
  box-shadow: 0 0 5px #DDD;
}

.textShadowW {
  text-shadow: 0 1px 0 #E7E7E7;
}

.textShadowB {
  text-shadow: 0 1px 0 #111;
}

.radius5 {
  border-radius: 5px;
}

.radius10 {
  border-radius: 10px;
}

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
  overflow-x: hidden;
  background: #FFF;
  font-size: 10px;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  overflow-x: hidden;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  word-wrap: break-word;
}

/**
 * Basic styles for links
 */
a {
  color: #36C;
  text-decoration: none;
}
a:hover {
  color: #36C;
  text-decoration: underline;
}

.pcDisplay {
  display: block !important;
}
@media only screen and (max-width: 768px) {
  .pcDisplay {
    display: none !important;
  }
}

.spDisplay {
  display: none !important;
}
@media only screen and (max-width: 768px) {
  .spDisplay {
    display: block !important;
  }
}

body {
  color: #222;
  font-weight: normal;
  font-size: 1.3rem;
  line-height: 1.231;
  font-family: Noto Sans JP, ヒラギノ角ゴシック Pro, Hiragino Kaku Gothic Pro, メイリオ, Meiryo, Osaka, ＭＳ Ｐゴシック, MS PGothic, Verdana, Arial, sans-serif;
}

/**
 * Clear inner floats
 */
.clearfix::after {
  clear: both;
  content: "";
  display: table;
}

/**
 * Hide text while making it readable for screen readers
 * 1. Needed in WebKit-based browsers because of an implementation bug;
 *    See: https://code.google.com/p/chromium/issues/detail?id=457146
 */
.hide-text {
  overflow: hidden;
  padding: 0;
  /* 1 */
  text-indent: 101%;
  white-space: nowrap;
}

/**
 * Hide element while making it readable for screen readers
 * Shamelessly borrowed from HTML5Boilerplate:
 * https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
 */
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

#header {
  position: fixed;
  background: #fff;
  box-shadow: -2px 0px 6px 2px rgba(0, 0, 0, 0.14);
  width: 100%;
  z-index: 2;
}

.hInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  vertical-align: middle;
  margin: 0 auto;
  padding: 0 15px;
  max-width: 1080px;
}
.hInner .logo {
  margin: 12px 20px 0 14px;
  max-width: 185px;
  width: 100%;
}
@media only screen and (max-width: 1040px) {
  .hInner .logo {
    margin: 0 15px 0 0;
    max-width: 130px;
  }
}
@media only screen and (max-width: 768px) {
  .hInner {
    justify-content: center;
    position: relative;
    padding: 13px 60px;
  }
  .hInner .logo {
    margin: 0;
    max-width: 100px;
  }
  .hInner .menu {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    text-align: center;
  }
  .hInner .menuToggle {
    display: inline-block;
    position: relative;
    width: 25px;
    height: 20px;
  }
  .hInner .menuToggle,
.hInner .menuToggle:before,
.hInner .menuToggle:after,
.hInner .menuToggle span {
    box-sizing: border-box;
    display: inline-block;
    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
  }
  .hInner .menuToggle span,
.hInner .menuToggle:before,
.hInner .menuToggle:after {
    content: "";
    background-color: #0086c3;
    border-radius: 100px;
    height: 4px;
    left: 0;
    position: absolute;
    width: 100%;
  }
  .hInner .menuToggle:before {
    top: 0px;
  }
  .hInner .menuToggle span {
    top: 8px;
  }
  .hInner .menuToggle:after {
    bottom: 0;
  }
}

.hNavi {
  width: calc(100% - 205px);
}
.hNavi .navi {
  display: flex;
  justify-content: flex-end;
  height: 100px;
}
.hNavi .navi a {
  display: flex;
  align-items: center;
  position: relative;
  padding: 13px;
  height: 100%;
  color: #222;
  font-size: 14px;
  text-decoration: none;
  transition: all 0.4s;
}
.hNavi .navi a:hover {
  background-color: #F2F2F2;
}
.hNavi .navi a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #56B2DC;
  width: 0;
  height: 4px;
  transition: all 0.4s;
}
.hNavi .navi a:hover::after {
  width: 100%;
  transition: all 0.4s;
}
.hNavi .navi a.active {
  background-color: #F2F2F2;
}
.hNavi .navi a.active::after {
  width: 100%;
}
@media only screen and (max-width: 1040px) {
  .hNavi {
    width: calc(100% - 145px);
  }
  .hNavi .navi {
    height: 70px;
  }
  .hNavi .navi a {
    padding: 8px;
    font-size: 11px;
  }
}
@media only screen and (max-width: 768px) {
  .hNavi {
    display: none;
    position: absolute;
    padding: 25px 0 15px;
    top: 0;
    left: 0;
    background: #fff;
    width: 100%;
    height: 100vh;
    z-index: 10;
  }
  .hNavi .navi {
    display: block;
  }
  .hNavi .navi a {
    font-size: 14px;
    justify-content: flex-end;
  }
  .hNavi .navi a::after {
    display: none;
  }
}

#naviClose {
  display: none;
  position: absolute;
  width: 22px;
  height: 22px;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}
#naviClose img {
  display: block;
  margin: 0 auto;
}
@media only screen and (max-width: 768px) {
  #naviClose {
    display: block;
  }
}

.inner {
  margin-left: auto;
  margin-right: auto;
  padding-left: 25px;
  padding-right: 25px;
  max-width: 870px;
  width: 100%;
}

.inner01 {
  margin-left: auto;
  margin-right: auto;
  padding-left: 25px;
  padding-right: 25px;
  max-width: 950px;
  width: 100%;
}

.inner02 {
  margin-left: auto;
  margin-right: auto;
  padding-left: 25px;
  padding-right: 25px;
  max-width: 1000px;
  width: 100%;
}

.inner03 {
  margin-left: auto;
  margin-right: auto;
  padding-left: 25px;
  padding-right: 25px;
  max-width: 1060px;
  width: 100%;
}

#footer {
  padding: 60px 0 30px;
  background: #F2F2F2;
}
@media only screen and (max-width: 768px) {
  #footer {
    padding: 32px 0 40px;
  }
}

.fInner {
  display: flex;
  justify-content: space-between;
  max-width: 1010px;
}
.fInner .fNavi {
  display: flex;
}
.fInner .fNavi ul {
  margin-right: 60px;
}
.fInner .fNavi ul:last-child {
  margin-right: 0;
}
.fInner .fNavi li {
  margin-bottom: 30px;
}
.fInner .fNavi a {
  color: #222;
  font-size: 14px;
  text-decoration: none;
}
.fInner .copyright {
  align-self: flex-end;
  text-align: center;
}
.fInner .copyright p {
  font-size: 14px;
  margin-top: 15px;
}
.fInner .copyright img {
  max-width: 185px;
}
@media only screen and (max-width: 768px) {
  .fInner {
    display: block;
  }
  .fInner .fNavi {
    display: block;
    margin-bottom: 85px;
  }
  .fInner .fNavi ul {
    margin-right: 0;
  }
  .fInner .copyright {
    width: 190px;
    margin: 0 0 0 auto;
  }
  .fInner .copyright img {
    max-width: 145px;
  }
  .fInner .copyright p {
    margin-top: 11px;
  }
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 24px 15px;
  border-radius: 8px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
  background: #56B2DC;
  color: #fff;
  width: 100%;
  max-width: 365px;
  border: 0;
  outline: none;
  appearance: none;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  transition: 0.3s;
}
.btn:hover {
  background: #44829E;
  color: #fff;
  text-decoration: none;
  transition: 0.3s;
  cursor: pointer;
}
@media only screen and (max-width: 768px) {
  .btn {
    margin: 0 auto;
    box-shadow: 0px 5px 9px 1px rgba(0, 0, 0, 0.17);
    max-width: 290px;
    padding: 19px 15px 18px;
    font-size: 16px;
  }
}

#keyv .btn {
  max-width: 420px;
}

.btnList {
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
  max-width: 884px;
  margin: 0 auto 60px;
}
.btnList li {
  width: calc((100% - 40px) / 2);
}
@media only screen and (max-width: 768px) {
  .btnList {
    margin: 0 auto 50px;
  }
  .btnList li {
    width: 100%;
    margin-bottom: 30px;
  }
  .btnList li:last-child {
    margin-bottom: 0;
  }
}

.contactBtn {
  max-width: 430px;
  margin: 0 auto;
}
.contactBtn01 {
  padding: 0 10px;
}

.btnCommon {
  display: inline-block;
  margin-bottom: 20px;
  padding: 9px 15px;
  font-weight: bold;
  background-color: #2291AF;
  color: #FFF;
  font-size: 13px;
}

.blockTwoBtn {
  display: flex;
  justify-content: space-between;
  max-width: 810px;
  width: 100%;
  margin: 0 auto;
  padding-top: 60px;
}
.blockTwoBtn.style01 {
  padding-top: 40px;
}
.blockTwoBtn .btn {
  width: calc(50% - 35px);
  max-width: 365px;
}
@media only screen and (max-width: 768px) {
  .blockTwoBtn {
    flex-direction: column;
    padding-top: 50px;
  }
  .blockTwoBtn.style01 {
    padding-top: 50px;
  }
  .blockTwoBtn .btn {
    width: 100%;
    max-width: 290px;
    margin-bottom: 30px;
    padding: 21px 10px 17px;
    font-size: 15px;
  }
  .blockTwoBtn .btn:last-child {
    margin-bottom: 0;
  }
}

.headline1 {
  margin-bottom: 70px;
  padding-bottom: 35px;
  border-bottom: 1px solid #C4C4C4;
  color: #56B2DC;
  font-size: 34px;
  text-align: center;
  font-family: "Petrona", serif;
}
.headline1 span {
  display: block;
  margin-top: 20px;
  font-family: "Noto Sans JP";
  color: #222;
  font-size: 27px;
  line-height: 1.5;
}
.headline1.style01 {
  margin-bottom: 40px;
}
@media only screen and (max-width: 768px) {
  .headline1 {
    margin-bottom: 47px;
    padding-bottom: 47px;
    font-size: 34px;
  }
  .headline1 span {
    margin-top: 20px;
    font-size: 27px;
  }
  .headline1.style01 {
    margin-bottom: 30px;
    padding-bottom: 17px;
  }
  .headline1.style01 span {
    line-height: 2.2;
    margin-top: 10px;
  }
}

.headline2 {
  display: inline-flex;
  align-items: center;
  font-size: 27px;
  font-weight: bold;
  margin-bottom: 20px;
}
.headline2 em {
  font-size: 14px;
  margin-right: 10px;
  margin-top: 5px;
}
.headline2 span {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: #fff;
  min-width: 33px;
  height: 33px;
  font-family: "Petrona", serif;
  font-size: 20px;
  font-weight: normal;
  z-index: 1;
}
.headline2 span::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  background: #56B2DC;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
@media only screen and (max-width: 768px) {
  .headline2 {
    margin-bottom: 15px;
    font-size: 24px;
  }
}

.headline3 {
  display: block;
  width: fit-content;
  margin: 0 auto 35px;
  text-align: center;
}
.headline3 span {
  background: linear-gradient(0deg, #94dbfc 30%, #94dbfc 60%) repeat-x left 0% bottom 0;
  background-size: 100% 15%;
  font-size: 27px;
  line-height: 2;
}
@media only screen and (max-width: 768px) {
  .headline3 {
    margin-bottom: 30px;
    font-size: 18px;
  }
  .headline3 span {
    font-size: 18px;
  }
}

#keyv {
  margin: 0 auto;
  padding: 100px 0 0;
  max-width: 1080px;
}
#keyv .kInner {
  padding: 29px 0 20px 61px;
  background: url(/assets/img/home/bg_keyv_pc.png) no-repeat right 0 bottom 0;
  background-size: cover;
}
#keyv .kInner .title {
  font-family: "Petrona", serif;
  font-size: 54px;
  background: -webkit-linear-gradient(#6FC3E9, #94DBFC, #56B2DC);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#keyv .kSection {
  width: 65%;
}
#keyv .kSection .ttl {
  margin-bottom: 10px;
  line-height: 2;
  font-size: 23px;
}
#keyv .kSection .txt {
  margin-bottom: 15px;
  line-height: 2.1;
  font-size: 15px;
}
#keyv .kSection .txt span {
  background: linear-gradient(0deg, #94dbfc 50%, #94dbfc 80%) repeat-x left 0% bottom -1px;
  background-size: 100% 35%;
  font-weight: bold;
}
@media only screen and (max-width: 1040px) {
  #keyv {
    padding-top: 62px;
  }
  #keyv .kInner {
    padding: 25px 30px 20px;
  }
  #keyv .kInner .title {
    font-size: 48px;
  }
  #keyv .kSection .ttl {
    margin-bottom: 15px;
    font-size: 21px;
  }
  #keyv .kSection .txt {
    line-height: 2.2;
    font-size: 14px;
  }
  #keyv .kSection .btn {
    padding: 20px 15px 20px;
    max-width: 320px;
    font-size: 16px;
  }
}
@media only screen and (max-width: 768px) {
  #keyv .blockSp {
    position: relative;
  }
  #keyv .blockSp .image img {
    vertical-align: middle;
    width: 100%;
    object-fit: cover;
  }
  #keyv .blockSp .ttlSp {
    position: absolute;
    bottom: 30px;
    left: 50%;
    width: 100%;
    color: #FFF;
    font-size: 34px;
    font-family: "Petrona", serif;
    font-weight: bold;
    text-align: center;
    transform: translateX(-50%);
  }
  #keyv .kInner {
    padding: 25px 25px 20px;
    background: #FFF;
  }
  #keyv .kSection {
    width: 100%;
  }
  #keyv .kSection .ttl {
    line-height: 1.95;
    font-size: 23px;
    text-align: center;
  }
  #keyv .kSection .txt {
    margin-bottom: 0;
    font-size: 14px;
  }
  #keyv .kSection .txt span {
    background-position: left 0% bottom 1px;
  }
}
@media only screen and (max-width: 475px) {
  #keyv {
    background-size: contain;
  }
}

.listCommon01 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.listCommon01::after {
  width: calc((100% - 70px) / 3);
  content: "";
}
.listCommon01 li {
  width: calc((100% - 70px) / 3);
  margin-bottom: 35px;
  background-color: #FFF;
}
.listCommon01 li img {
  display: block;
  width: 100%;
  margin: 0 auto;
}
@media only screen and (max-width: 768px) {
  .listCommon01 {
    flex-direction: column;
    padding: 0;
  }
  .listCommon01::after {
    display: none;
  }
  .listCommon01 li {
    width: 100%;
    max-width: 285px;
    margin: 0 auto 15px auto;
  }
  .listCommon01 li:last-child {
    margin-bottom: 0;
  }
}

.listCommon02 {
  display: flex;
  margin: 0 -10px;
}
.listCommon02 li {
  width: calc(25% - 20px);
  margin: 0 10px 0 10px;
}
.listCommon02 li .ttl {
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: bold;
  line-height: 2;
  text-align: center;
}
.listCommon02 li .image {
  margin-bottom: 15px;
}
.listCommon02 li .image img {
  display: block;
  margin: 0 auto;
}
.listCommon02 li .txt {
  font-size: 15px;
  line-height: 1.7;
}
@media only screen and (max-width: 768px) {
  .listCommon02 {
    margin: 0;
    flex-direction: column;
  }
  .listCommon02 li {
    width: 100%;
    margin: 0 0 40px 0;
  }
  .listCommon02 li:last-child {
    margin-bottom: 0;
  }
  .listCommon02 li .image img {
    max-width: 230px;
  }
}

.groupTag {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -2px 20px -2px;
}
.groupTag .tag {
  margin: 0 2px 9px 2px;
}
@media only screen and (max-width: 768px) {
  .groupTag {
    margin: 0 -3px 20px -3px;
  }
  .groupTag .tag {
    margin: 0 3px 9px 3px;
  }
}

.tag {
  display: inline-flex;
  padding: 2px 2px;
  border: 1px solid #2291AF;
  border-radius: 2px;
  font-size: 15px;
  background-color: #FFF;
}
@media only screen and (max-width: 768px) {
  .tag {
    padding: 1px 1px;
  }
}

.bgAqua {
  padding: 80px 0;
  background-color: #F4F8F9 !important;
}
@media only screen and (max-width: 768px) {
  .bgAqua {
    padding: 40px 0 45px 0;
    background-color: #FFF !important;
  }
}

.bgWhite {
  padding: 80px 0;
  background-color: #FFF !important;
}
@media only screen and (max-width: 768px) {
  .bgWhite {
    padding: 40px 0 45px 0;
    background-color: #F4F8F9 !important;
  }
}

.txtCommon {
  font-size: 15px;
  line-height: 2.5;
}
@media only screen and (max-width: 768px) {
  .txtCommon {
    line-height: 2.3;
  }
}

.txtCommon01 {
  font-size: 13px;
  line-height: 1.8;
}

.txtCommon02 {
  font-size: 13px;
  line-height: 2.5;
}
@media only screen and (max-width: 768px) {
  .txtCommon02 {
    font-size: 10px;
    line-height: 2.3;
  }
}

.listSupport {
  display: flex;
}
.listSupport li {
  padding: 0 10px;
  border-right: 2px dotted #94DBFC;
  width: calc(100% / 3);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: space-between;
}
.listSupport li:last-child {
  border-right: none;
}
.listSupport li .ttl {
  margin-bottom: 30px;
  font-size: 25px;
  line-height: 1.8;
  font-weight: bold;
  min-height: 90px;
}
.listSupport li .ttl2 {
  margin-bottom: 15px;
  font-size: 18px;
  line-height: 1.8;
  font-weight: bold;
}
.listSupport li .price {
  margin-bottom: 20px;
  line-height: 2;
  font-size: 15px;
}
.listSupport li .price span {
  font-size: 18px;
  font-weight: bold;
  color: #2291AF;
}
.listSupport li .boxImg {
  margin: 0 auto;
  max-width: 165px;
  width: 100%;
}
.listSupport li .boxImg img {
  display: block;
  margin: 0 auto;
  width: 100%;
}
.listSupport li .boxImg2 {
  margin: 0 auto;
  max-width: 230px;
  width: 100%;
}
.listSupport li .boxImg2 img {
  display: block;
  margin: 0 auto;
}
.listSupport .headline2 {
  justify-content: center;
  margin-bottom: 15px;
}
.listSupport .ttl {
  margin-bottom: 38px;
  font-size: 20px;
}
@media only screen and (max-width: 768px) {
  .listSupport {
    flex-direction: column;
  }
  .listSupport li {
    padding: 55px 0;
    border-right: none;
    border-bottom: 2px dotted #94DBFC;
    width: 100%;
  }
  .listSupport li .boxImg {
    max-width: 140px;
  }
  .listSupport li:first-child {
    padding-top: 0;
  }
  .listSupport li:last-child .sGroup {
    margin-bottom: 37px;
  }
  .listSupport li:last-child .boxImg {
    max-width: 160px;
  }
  .listSupport li .ttl {
    margin-bottom: 22px;
    font-size: 18px;
    line-height: 1.6;
    min-height: auto;
  }
  .listSupport li .ttl2 {
    font-size: 18px;
  }
  .listSupport li .price {
    margin-bottom: 15px;
  }
  .listSupport.style01 li:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }
}

@media all and (-ms-high-contrast: none) {
  .listSupport li {
    display: block;
  }
}
.giftlogisticsSection {
  padding: 80px 0;
}
@media only screen and (max-width: 768px) {
  .giftlogisticsSection {
    padding: 55px 0;
  }
}

.boxContent {
  display: flex;
  justify-content: space-between;
}
.boxContent .boxImg {
  width: 49%;
}
.boxContent .boxImg img {
  vertical-align: middle;
}
.boxContent .boxInfo {
  width: calc(50% - 45px);
}
.boxContent .boxInfo .ttl {
  margin-bottom: 30px;
  font-size: 27px;
  font-weight: bold;
  color: #222;
  line-height: 1.5;
}
.boxContent .boxInfo .txt {
  font-size: 15px;
  line-height: 2.15;
  margin-bottom: 25px;
}
.boxContent02 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.boxContent02 .blockTxt {
  width: calc(50% - 80px);
}
.boxContent02 .blockTxt .headline3 {
  margin: 0 0 55px;
  text-align: left;
}
.boxContent02 .imgDiagram {
  width: 50%;
}
.boxContent02 .imgDiagram img {
  vertical-align: middle;
}
@media only screen and (max-width: 768px) {
  .boxContent {
    flex-wrap: wrap;
  }
  .boxContent .boxImg {
    margin: 0 auto 20px;
    width: 100%;
    text-align: center;
  }
  .boxContent .boxInfo {
    margin-bottom: 15px;
    width: 100%;
  }
  .boxContent .boxInfo .ttl {
    margin-bottom: 20px;
    font-size: 18px;
  }
  .boxContent .boxInfo .txt {
    line-height: 1.8;
    margin-bottom: 0;
  }
  .boxContent02 {
    flex-wrap: wrap;
  }
  .boxContent02 .blockTxt {
    width: 100%;
    margin-bottom: 20px;
  }
  .boxContent02 .blockTxt .headline3 {
    margin: 0 auto 30px;
    text-align: center;
  }
  .boxContent02 .imgDiagram {
    width: 100%;
    max-width: 260px;
    margin: 0 auto;
  }
}

.blockEndow .headline2 {
  position: relative;
  margin-bottom: 12px;
  padding-bottom: 15px;
  width: fit-content;
}
.blockEndow .headline2::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  background: url(/assets/img/common/bg_circle.png) repeat-x left -2px top;
  width: 100%;
  height: 5px;
}

.sectionServices .txtCommon {
  padding: 0 50px;
}
.sectionServices .servicesImage {
  display: block;
  margin: 0 auto;
}
.sectionServices .servicesImage img {
  display: block;
  margin: 0 auto;
}
@media only screen and (max-width: 768px) {
  .sectionServices .txtCommon {
    padding: 0;
  }
  .sectionServices .servicesImage {
    max-width: 290px;
  }
}

.sectionSchedule .scheduleImage {
  display: block;
  margin: 0 auto 50px auto;
}
.sectionSchedule .scheduleImage img {
  display: block;
  width: 100%;
  margin: 0 auto;
}
@media only screen and (max-width: 768px) {
  .sectionSchedule .scheduleImage {
    max-width: 290px;
  }
}

.sectionOption .headline1 {
  margin-bottom: 25px;
}
@media only screen and (max-width: 768px) {
  .sectionOption .headline1 {
    line-height: 1.8;
    padding-bottom: 40px;
    margin-bottom: 45px;
  }
  .sectionOption .headline1 span {
    margin-top: 15px;
  }
}

.blockText {
  margin-bottom: 80px;
  padding-left: 10px;
}
@media only screen and (max-width: 768px) {
  .blockText {
    margin-bottom: 50px;
    padding-left: 0;
  }
}

.contactSection {
  padding: 80px 0;
  text-align: center;
}
.contactSection .headline1 {
  margin-bottom: 40px;
  padding-bottom: 0;
  border-bottom: 0;
}
.contactSection .headline1 span {
  margin-top: 22px;
  font-size: 20px;
}
.contactSection .boxTxt {
  margin-bottom: 50px;
}
.contactSection .boxTxt .txt {
  font-size: 15px;
  line-height: 2;
}
.contactSection .btn {
  padding: 28px 15px;
  border: none;
  max-width: 465px;
  cursor: pointer;
}
@media only screen and (max-width: 768px) {
  .contactSection {
    padding: 40px 0 45px 0;
  }
  .contactSection .headline1 {
    margin-bottom: 22px;
  }
  .contactSection .btn {
    padding: 16px 15px;
    max-width: 290px;
  }
}

.ieMode .kInner .title {
  color: #6FC3E9;
}

.container {
  margin: 0 auto 35px;
  padding: 30px 50px 22px;
  max-width: 890px;
  background-color: #F4F8F9;
}
.container input[type=text], .container select, .container textarea {
  width: 100%;
  padding: 17px 16px;
  border: none;
  box-shadow: inset 0px 0px 15px -2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 20px;
  font-size: 14px;
  resize: none;
  outline: none;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}
.container select {
  -moz-appearance: none;
  appearance: none;
  background-image: url(/assets/img/common/icon_arrrow.png);
  background-repeat: no-repeat;
  background-position: right 35px top 50%;
  background-size: 16px;
}
.container select::-ms-expand {
  display: none;
}
.container textarea {
  padding: 52px 40px;
  height: 400px;
  overflow: auto;
}
@media only screen and (max-width: 768px) {
  .container {
    margin-bottom: 50px;
    padding: 15px 13px;
  }
  .container select {
    background-position: right 20px top 50%;
    background-size: 13px;
  }
  .container textarea {
    padding: 35px 16px;
    height: 265px;
  }
}