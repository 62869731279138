// -----------------------------------------------------------------------------
// Header style
// -----------------------------------------------------------------------------
#header {
	position: fixed;
	background: #fff;
	box-shadow: -2px 0px 6px 2px rgb(0 0 0 / 14%);
	width: 100%;
	z-index: 2;
}
.hInner {
	display: flex;
	justify-content: space-between;
	align-items: center;
	vertical-align: middle;
	margin: 0 auto;
	padding: 0 15px;
	max-width: 1080px;
	.logo {
		margin: 12px 20px 0 14px;
		max-width: 185px;
		width: 100%;
	}
	@include media-down(1040) {
		.logo {
			margin: 0 15px 0 0;
			max-width: 130px;
		}
	}
	@include media-down(sp) {
		justify-content: center;
		position: relative;
		padding: 13px 60px;
		.logo {
			margin: 0;
			max-width: 100px;
		}
		.menu {
			position: absolute;
			right: 20px;
			top: 50%;
			transform: translateY(-50%);
			display: block;
			text-align: center;
		}
		.menuToggle {
			display: inline-block;
			position: relative;
			width: 25px;
			height: 20px;
		}
		.menuToggle,
		.menuToggle:before,
		.menuToggle:after,
		.menuToggle span {
			box-sizing:border-box;
			display:inline-block;
			-webkit-transition:all 0.4s ease 0s;
			   -moz-transition:all 0.4s ease 0s;
					transition:all 0.4s ease 0s;
		}
		.menuToggle span,
		.menuToggle:before,
		.menuToggle:after {
			content: "";
			background-color: #0086c3;
			border-radius: 100px;
			height: 4px;
			left: 0;
			position: absolute;
			width: 100%;
		}
		.menuToggle:before {
			top: 0px;
		}
		.menuToggle span {
			top: 8px;
		}
		.menuToggle:after {
			bottom: 0;
		}
	}
}
.hNavi {
	width: calc(100% - 205px);
	.navi {
		display: flex;
		justify-content: flex-end;
		height: 100px;
		a {
			display: flex;
			align-items: center;
			position: relative;
			padding: 13px;
			height: 100%;
			color: #222;
			font-size: 14px;
			text-decoration: none;
			transition: all 0.4s;
			&:hover {
				background-color: #F2F2F2;
			}
			&::after {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				background-color: #56B2DC;
				width: 0;
				height: 4px;
				transition: all 0.4s;
			}
			&:hover::after {
				width: 100%;
				transition: all 0.4s;
			}
			&.active {
				background-color: #F2F2F2;
				&::after {
					width: 100%;
				}
			}
		}
	}
	@include media-down(1040) {
		width: calc(100% - 145px);
		.navi {
			height: 70px;
			a {
				padding: 8px;
				font-size: 11px;
			}
		}
	}
	@include media-down(sp) {
		display: none;
		position: absolute;
		padding: 25px 0 15px;
		top: 0;
		left: 0;
		background: #fff;
		width: 100%;
		height: 100vh;
		z-index: 10;
		.navi {
			display: block;
			a {
				font-size: 14px;
				justify-content: flex-end;
				&::after {
					display: none;
				}
			}
		}
	}
}

#naviClose {
	display: none;
	position: absolute;
	width: 22px;
	height: 22px;
	bottom: 20px;
	left: 50%;
	transform: translateX(-50%);
	img {
		display: block;
		margin: 0 auto;
	}
	@include media-down(sp) {
		display: block;
	}
}
