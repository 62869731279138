// -----------------------------------------------------------------------------
// Footer style
// -----------------------------------------------------------------------------

#footer {
	padding: 60px 0 30px;
	background: #F2F2F2;
	@include media-down(sp) {
		padding: 32px 0 40px;
	}
}
.fInner {
	display: flex;
	justify-content: space-between;
	max-width: 1010px;
	.fNavi {
		display: flex;
		ul {
			margin-right: 60px;
			&:last-child {
				margin-right: 0;
			}
		}
		li {
			margin-bottom: 30px;
		}
		a {
			color: #222;
			font-size: 14px;
			text-decoration: none;
		}
	}
	.copyright {
		align-self: flex-end;
		text-align: center;
		p {
			font-size: 14px;
			margin-top: 15px;
		}
		img {
			max-width: 185px;
		}
	}
	@include media-down(sp) {
		display: block;
		.fNavi {
			display: block;
			margin-bottom: 85px;
			ul {
				margin-right: 0;
			}

		}
		.copyright {
			width: 190px;
			margin: 0 0 0 auto;
			img {
				max-width: 145px;
			}
			p {
				margin-top: 11px;
			}
		}
	}
}
