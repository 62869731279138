// -----------------------------------------------------------------------------
// Layout Settings
// -----------------------------------------------------------------------------
 @mixin inner($maxW) {
    margin-left: auto;
    margin-right: auto;
    padding-left: 25px;
    padding-right: 25px;
    max-width: $maxW;
    width: 100%;
}

.inner {
    @include inner(870px);
}

.inner01 {
    @include inner(950px);
}

.inner02 {
    @include inner(1000px);
}

.inner03 {
    @include inner(1060px);
}
